var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotation-table",style:({
    borderTop:
      (_vm.lineGroup && _vm.lineGroup.length > 0) ||
      (_vm.tableData && _vm.tableData.length > 0)
        ? _vm.borderStyle
        : 0,
  })},[(_vm.tableData.length > 0)?_c('ul',[_c('li',{staticClass:"checkbox-li"},[_c('el-checkbox',{model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1),_c('li',{staticClass:"number-li"},[_vm._v("#")]),_vm._l((_vm.tableAttr),function(attr,idx){return _c('li',{key:idx,ref:"tableHeader",refInFor:true,staticStyle:{"width":"calc((100% - 210px) / 7)"}},[_c('el-tooltip',{staticClass:"item",attrs:{"content":attr.label,"placement":"top"}},[_c('span',{staticClass:"tootip-span"},[_vm._v(" "+_vm._s(attr.label)+" ")])])],1)}),_c('li',{staticClass:"button-li"},[_c('span',{staticClass:"pull-right",on:{"click":_vm.toggleExpand}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":_vm.expandAllLine ? '#icon-expandLine' : '#icon-expandLine1'}})])])])],2):_vm._e(),_c('draggable',{ref:"draggBox",staticClass:"draggBox",style:({
      maxHeight: _vm.draggBoxHeight,
      borderBottom:
        (_vm.lineGroup && _vm.lineGroup.length > 0) ||
        (_vm.tableDataArr && _vm.tableDataArr.length > 0)
          ? _vm.borderStyle
          : 0,
    }),attrs:{"group":"site","animation":"300","dragClass":"dragClass","ghostClass":"ghostClass","chosenClass":"chosenClass","options":{ group: { name: 'site' }, sort: false },"id":_vm.groupIndex},on:{"remove":function($event){return _vm.remove($event)}},model:{value:(_vm.tableDataArr),callback:function ($$v) {_vm.tableDataArr=$$v},expression:"tableDataArr"}},[_c('transition-group',_vm._l((_vm.tableData),function(lineData,idx){return _c('quotation-line',{key:idx,attrs:{"quoteId":_vm.quoteId,"groupId":_vm.groupId,"id":lineData.index,"line-data":lineData,"tableAttr":_vm.tableAttr,"checkbox":_vm.checkbox,"checkAll":_vm.checkAll,"showIndex":_vm.showIndex,"buttonList":_vm.buttonList,"currency":_vm.currency,"editGroupIndex":_vm.editGroupIndex,"editLineIndex":_vm.editLineIndex,"editField":_vm.editField,"pricebookId":_vm.pricebookId,"expandAllLine":_vm.expandAllLine,"groupIndex":_vm.groupIndex,"moreQuoteLineFieldList":_vm.moreQuoteLineFieldList},on:{"edit":_vm.edit,"modify":_vm.modify,"checkLine":_vm.checkLine,"deleteQuotation":_vm.deleteQuotation,"loading":_vm.loading,"copyQuotation":_vm.copyQuotation,"configQuotation":_vm.configQuotation}})}),1)],1),(_vm.customertotal !== undefined)?_c('div',{staticClass:"subotalBox"},[_c('span',{staticClass:"subtotal"},[_vm._v(_vm._s(_vm.$t("label.subtotal")))]),_c('span',[_vm._v(_vm._s(_vm.customertotal))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }