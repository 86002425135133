<!--
 * @Description: 
 * @Author: 张亚欣
 * @Date: 2021-06-07 10:18:38
 * @LastEditTime: 2022-02-18 19:11:53
 * @LastEditors: Please set LastEditors
 * @Reference: 编辑报价行页面
-->
<template>
  <div class="editQuotationBox">
    <div class="main" v-show="pageType === 'quote'">
      <!-- 页头标题和按钮 -->
      <div class="top pull-left">
        <div class="left-content pull-left padding10">
          <!-- quotation/editQuotation -->
          <svg class="icon quotationImg pull-left" aria-hidden="true">
            <use href="#icon-cloudtab26_norm"></use>
          </svg>
          <div>
            <p class="quotationCode">{{ quoteInfo.numbers }}</p>
            <p class="title">{{ $t("label.edit.quote.line") }}</p>
          </div>
        </div>
        <div class="right-content pull-right">
          <el-button-group class="pull-right">
            <el-button size="mini" @click="cancel">
              {{ $t("label.cancel") }}
            </el-button>
            <el-button type="primary" size="mini" @click="save">
              {{ $t("label.save") }}
            </el-button>
          </el-button-group>
          <el-button-group class="pull-right margin10">
            <el-button size="mini" @click="quicksave">{{
              $t("label.quicksave")
            }}</el-button>
            <el-button size="mini" @click="recalculate">{{
              $t("label.recalculate")
            }}</el-button>
          </el-button-group>
          <el-button-group class="pull-right margin10">
            <el-button size="mini" @click="addLineGroup">{{
              $t("label.addQuoteLineGroup")
            }}</el-button>
            <!-- 有报价行分组时展示此按钮 -->
            <el-button
              size="mini"
              @click="cancelLineGroup"
              v-if="lineGroup.length && lineGroup.length > 0"
            >
              {{ $t("label.cancelQuoteLineGroup") }}
            </el-button>
            <el-button size="mini" @click="deleteQuoteLines">{{
              $t("label.batchDelete")
            }}</el-button>
            <el-button size="mini" @click="Throttless" v-show="isBatchCopy">{{
              $t("label.batchCopy")
            }}</el-button>
          </el-button-group>
          <!-- 无报价行分组时展示此按钮 -->
          <el-button
            size="mini"
            class="pull-right margin10"
            @click="addProduct"
            v-if="lineGroup.length === 0"
          >
            {{ $t("vue_label_opportunity_product_addproduct") }}
          </el-button>
        </div>
      </div>

      <div
        class="edit_box"
        :style="{ height: editBoxHeight }"
        v-loading="loading"
      >
        <navigation
          v-if="lineGroup.length && lineGroup.length > 1"
          :navList="lineGroup"
          :navName="navName"
          :isactive="isactive"
          @jump="jump"
        />
        <div class="edit_content">
          <!-- 报价单信息 -->
          <div class="quotationInfoBox">
            <p class="quotationInfoTitle">
              {{ $t("label.quotationInformation") }}
            </p>
            <el-form
              ref="form"
              class="tit_form"
              :model="quoteInfo"
              label-width="150px"
            >
              <div
                class="pull-left"
                style="width: 25%"
                v-for="item in quoteFieldList"
                :key="item.prop"
              >
                <el-form-item :label="item.label">
                  <el-date-picker
                    v-if="item.fieldtype === 'D'"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :format="dateFormat"
                    v-model="quoteInfo[item.prop]"
                    style="width: 100%"
                  ></el-date-picker>
                  <el-input-number
                    v-else
                    :type="item.type"
                    :controls="false"
                    style="width: 100%"
                    :placeholder="item.placeholder"
                    :min="item.valueInterval.min"
                    :max="item.valueInterval.max"
                    :precision="item.precision || 0"
                    controls-position="right"
                    v-model="quoteInfo[item.prop]"
                  >
                  </el-input-number>
                </el-form-item>
              </div>
            </el-form>
            <br />
            <div
              class="no_add"
              v-if="
                quoteLineFieldList.length > 0 &&
                quoteLineList.length === 0 &&
                lineGroup.length === 0
              "
            >
              <!-- 请点击“添加产品”，添加产品至报价单。 -->
              {{ $t("label.please.click.add.products.") }}
            </div>
          </div>
          <!-- 报价行组信息 -->
          <div class="line_wrap" v-if="lineGroup && lineGroup.length > 0">
            <div
              class="line_box"
              v-for="(groupItem, groupIndex) in lineGroup"
              :key="groupIndex"
            >
              <!-- 报价单行组 按钮 表单 -->
              <div class="line_group">
                <div class="btn_left" @click.self="infoBtn(groupItem)">
                  <div class="info_box">
                    <!-- 报价行组 -->
                    <div class="first_top">
                      <svg class="icon collection" aria-hidden="true">
                        <use href="#icon-shoucang"></use>
                      </svg>
                      <div
                        style="
                          position: relative;
                          padding-right: 15px;
                          box-sizing: border-box;
                        "
                        @mouseenter="mouseEnterName(groupIndex)"
                        @mouseleave="mouseLeaveName(groupIndex)"
                      >
                        <!-- {{ $t('label.the.reward.group') }}1  -->
                        <div v-if="!groupItem.isExhibitionName">
                          <span>{{ groupItem.name }}</span>
                          <svg
                            :class="['editIconName' + groupIndex, 'icon']"
                            aria-hidden="true"
                            @click.self="editNameBtn(groupItem)"
                            style="
                              position: absolute;
                              right: -11px;
                              top: 7px;
                              display: none;
                              cursor: pointer;
                              height: 12px;
                            "
                          >
                            <use href="#icon-pen"></use>
                          </svg>
                        </div>
                        <el-input
                          v-model="groupItem.name"
                          v-else
                          size="small"
                        />
                      </div>
                    </div>
                    <!-- 添加报价行组描述 -->
                    <div class="two_bottom">
                      <div
                        v-if="!groupItem.isExhibitionText"
                        style="position: relative"
                        @mouseenter="mouseEnterText(groupIndex)"
                        @mouseleave="mouseLeaveText(groupIndex)"
                      >
                        <span>{{ groupItem.description }}</span>
                        <svg
                          :class="['editIconText' + groupIndex, 'icon']"
                          aria-hidden="true"
                          @click.self="editTextBtn(groupItem)"
                          style="
                            position: absolute;
                            right: -12px;
                            top: 2px;
                            display: none;
                            cursor: pointer;
                            height: 12px;
                          "
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                      </div>
                      <el-input
                        v-model="groupItem.description"
                        v-else
                        size="small"
                      />
                    </div>
                  </div>
                  <el-button-group
                    style="margin-top: 16px"
                    v-if="buttonArr.length > 0"
                  >
                    <el-button
                      class="btn_box"
                      size="mini"
                      v-for="(item, index) in buttonArr.slice(0, 1)"
                      :key="index"
                      @click="quotationShowBtn(groupIndex)"
                      >{{ item.label }}</el-button
                    >
                    <el-popover
                      placement="bottom-end"
                      popper-class="pop_box"
                      trigger="hover"
                      :visible-arrow="false"
                      v-model="groupItem.buttonPopover"
                    >
                      <div class="xiala" slot="reference">
                        <svg class="icon" aria-hidden="true">
                          <use href="#icon-blue_sj"></use>
                        </svg>
                      </div>
                      <ul class="moreButton">
                        <li
                          v-for="(item, index) in buttonArr.slice(1, 4)"
                          :key="index"
                          @click="quotationMoreBtn(item, groupIndex, groupItem)"
                        >
                          <span>{{ item.label }}</span>
                        </li>
                      </ul>
                    </el-popover>
                  </el-button-group>
                </div>
                <div class="form_right">
                  <el-form ref="form" :model="groupItem" label-width="150px">
                    <div
                      class="pull-left"
                      style="width: 50%"
                      v-for="item in groupItem.quoteLineGroupFieldList"
                      :key="item.prop"
                    >
                      <el-form-item :label="item.label">
                        <el-date-picker
                          v-if="item.fieldtype === 'D'"
                          type="date"
                          value-format="yyyy-MM-dd"
                          :format="dateFormat"
                          v-model="groupItem[item.prop]"
                          style="width: 100%"
                        ></el-date-picker>
                        <el-input-number
                          v-else
                          :type="item.type"
                          :controls="false"
                          style="width: 100%"
                          :placeholder="item.placeholder"
                          :min="item.valueInterval.min"
                          :max="item.valueInterval.max"
                          :precision="item.precision || 0"
                          controls-position="right"
                          v-model="groupItem[item.prop]"
                        >
                        </el-input-number>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </div>
              <!-- 报价行组 - 报价行 -->
              <QuotationTable
                ref="quotationTable"
                v-if="quoteLineFieldList.length > 0"
                :quoteId="id"
                :groupId="groupItem.id"
                :tableAttr="quoteLineFieldList"
                :moreQuoteLineFieldList="moreQuoteLineFieldList"
                :tableData="groupItem.quoteLineJson"
                :currency="currency"
                :pricebookId="manualForm.pricebookId"
                :customertotal="groupItem.customertotal"
                :lineGroup="lineGroup"
                :groupIndex="groupIndex"
                @modify="modify"
                @checkLine="checkLine"
                @deleteQuotation="deleteQuoteLine"
                @loading="addLoading"
                @copyQuotation="Throttles"
                @configQuotation="configQuotation"
                @draggleData="draggleData"
              ></QuotationTable>
              <div
                class="no_footer"
                v-if="groupItem.quoteLineJson.length === 0"
              >
                <!-- 该报价行组目前没有报价行项目，请点击“添加产品”按钮添加产品。-->
                {{
                  $t(
                    "label.there.is.no.quotation.line.item.in.this.quotation.line.group.at.present."
                  )
                }}
              </div>
            </div>
          </div>
          <!-- 未分组时报价行 -->
          <QuotationTable
            v-else-if="quoteLineFieldList.length > 1"
            ref="quotationTable"
            :quoteId="id"
            :tableAttr="quoteLineFieldList"
            :moreQuoteLineFieldList="moreQuoteLineFieldList"
            :tableData="quoteLineList"
            :currency="currency"
            :pricebookId="manualForm.pricebookId"
            @modify="modify"
            @checkLine="checkLine"
            @deleteQuotation="deleteQuoteLine"
            @loading="addLoading"
            @copyQuotation="Throttles"
            @configQuotation="configQuotation"
          ></QuotationTable>
          <!-- 报价单总计 -->
          <div
            class="totalQuotation"
            v-show="
              (lineGroup && lineGroup.length > 0) ||
              (quoteLineList && quoteLineList.length > 0)
            "
          >
            {{ $t("label.totalQuotation") }}: {{ quoteInfo.netamount }}
          </div>
        </div>
      </div>
    </div>

    <!-- 选择价格手册 -->
    <el-dialog
      :title="$t('lable.opppdt.selPbk.title')"
      :visible.sync="manualVisible"
      width="423px"
      :top="top"
      :before-close="manualClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      class="manual"
    >
      <el-form
        :model="manualForm"
        :rules="manualRules"
        ref="pricebookForm"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <el-form-item prop="pricebookId">
          <el-select
            v-model="manualForm.pricebookId"
            style="width: 351px; height: 30px"
          >
            <el-option
              v-for="val in pricebooks"
              :key="val.id"
              :label="val.name"
              :value="val.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manualClose" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button type="primary" @click="submitForm" size="small">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="()=>(dialogVisible = false)"
    >
      <span style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 确认删除该条数据吗？ -->
        {{ delContent }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmDel" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.delete") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 多产品复制 -->
    <el-dialog
      :visible.sync="handleCopy"
      :title="$t('label.copying.reward.groups')"
      top="15%"
      width="1124px"
      :close-on-click-modal="false"
      :before-close="handleCopyy"
    >
      <span>{{ $t("label.select.one.group") }}</span>
      <span style="font-size: 16px; font-weight: bold; word-break: break-word">
        <el-transfer v-model="groupValue" :data="groupData" :titles="titles">
        </el-transfer>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCopyy" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmCopyy" type="primary" size="mini">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 选择产品 -->
    <ChooseProducts
      ref="chooseProducts"
      v-show="pageType === 'chooseProduct'"
      :pricebookid="manualForm.pricebookId"
      :id="id"
      :currency="currency"
      :groupIndex="groupIndex"
      @checkData="checkData"
    ></ChooseProducts>
    <!-- 配置产品 -->
   <!-- quoteId 原来是 空,修改了一下 -->
    <ConfigQuotation
      ref="configQuotation"
      v-if="pageType === 'configQuotation'"
      :pricebookid="manualForm.pricebookId"
      :currency="currency"
      :id="id"
      :quoteId="id"
      :groupIndex="groupIndex"
      :quotelineid="quotelineid"
      :configEcho="configEcho"
      :parentData="parentData"
      :pageTypes="pageTypes"
      @configSave="configSave"
    />
  </div>
</template>

<script>
import * as Time from "@/utils/time";
import QuotationTable from "./components/quotation-table";
import navigation from "./components/navigation.vue";
import ChooseProducts from "./chooseProducts.vue";
import ConfigQuotation from "./configQuotation.vue";
import * as quotationApi from "./api.js";
import INPUTTYPE from "@/config/enumCode/inputType";

export default {
  name: "editQuotation",
  components: {
    QuotationTable,
    navigation,
    ChooseProducts,
    ConfigQuotation,
  },
  data() {
    return {
      groupIndex:null,
      groupData: [],
      groupValue: [],
      index: 1, // 报价行序号
      loading: false,
      titles: [
        this.$i18n.t("label.optional.groups"),
        this.$i18n.t("label.selected.groups"),
      ],
      editBoxHeight: "",
      navName: this.$i18n.t("label.the.reward.group"), //'报价行组'
      isactive: 0,
      // objectApi: "cloudccquoteline", // 报价行对象objectApi
      // prefix: "036", //报价行对象前缀
      checkedIds: [], // 选中数据id集合
      checked: [], // 选中数据集合
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      dialogVisible: false, // 删除数据确认弹窗
      delContent: this.$i18n.t("label.confirmDelete"),
      handleCopy: false, //复制报价行弹窗
      isBatchCopy: false,
      //价格手册信息
      manualVisible: false,
      manualForm: {
        pricebookId: "", // 价格手册id
      },
      manualRules: {
        pricebookId: [
          {
            required: true,
            message: this.$i18n.t("message.oppdt.selectpricebook"),
            trigger: "blur",
          },
        ],
      },
      pricebooks: [],
      top: "13%",
      // autoRefresh: false,
      // twoColumnWidth: "25%",
      quoteFieldList: [], // 报价行信息
      // tootip: true,
      // checkAll: false,
      draggBoxHeight: "500px",
      // checkbox: true,
      // showIndex: true,
      quoteLineGroupFieldList: [], // 报价行组表单信息
      showQuoteLineFieldList: [
        "productcode",
        "productname",
        "quantity",
        "listprice",
        "additionaldiscount",
        "customerprice",
        "customertotal",
      ], // 报价行展示表头集合
      showMoreQuoteLineFieldList: [
        "pricingmethod",
        "originalprice",
        "specialprice",
        "regularprice",
        "startdate",
        "enddate",
      ], // 报价行展开行字段
      quoteLineFieldList: [], // 报价行表头信息
      moreQuoteLineFieldList: [], // 报价行展开行信息
      quoteLineList: [],
      quoteInfo: {},
      // t1: 0,
      // t2: 0,
      // timer: null,
      // childColumnWidth: "50%",
      // 导航栏数据
      // navList: [],
      lineGroup: [],
      addNum: 0,
      id: this.$route.params.id,
      currency: "", //币种
      // groupids: "",
      throttle: false, //节流函数
      buttonArr: [
        {
          name: "add",
          label: this.$i18n.t("vue_label_opportunity_product_addproduct"), //'添加产品',
        },
        {
          name: "copy",
          label: this.$i18n.t("label.copying.reward.groups"), //'复制报价行组'
        },
        {
          name: "delete",
          label: this.$i18n.t("label.delete.reward.groups"), //'删除报价行组'
        },
        // {
        //   name:'reset',
        //   label: this.$i18n.t('label.been.detained'), //'重置折扣'
        // },
      ],
      // discountEditable: false, // 折扣(%)字段编辑权限
      // additionaldiscountamountEditable: false, // 折扣(币种)字段编辑权限
      pageType: "quote", // quote、chooseProduct、config 表示所在页面
      customertotal: "0", //小计
      copyLineGroup: [], //拷贝的报价行组数据
      copyQuoteLineList: [], //拷贝的报价行数据
      groupArr: [], //报价行组数据
      deleteGroupIds: [], //汇总所有删除的报价行组id
      cancelGroupIds: [], //汇总所有取消的报价行组id
      quotelineid: "", //报价行id
      configEcho: [], //配置需要回显的数据
      parentData: [], //父产品传参，配置页面用
      listIndex: 0, //报价行下标重新配置用
      pageTypes: "quote", //判断那个页面跳的配置
      quotationNumber: "", //报价编号
      groupIds: 0,
    };
  },
  watch: {
    // 添加产品 传参千位符转数字  dj
    // quoteLineList(){
    //   this.quoteLineList.forEach(al=> {
    //     this.thousandtonum(al)
    //   })
    // },
    // 添加报价行组 传参千位符转数字  dj
    // lineGroup(){
    //   this.lineGroup[this.groupIds].quoteLineJson.forEach(al=> {
    //     this.thousandtonum(al)
    //   })
    // },
    handleCopy(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.groupValue = [];
        });
      }
    },
    groupValue() {
      //
    },
    pageType(nval) {
      if (nval === "quote") {
        this.checkedIds = [];
      }
    },
  },
  created() {
    this.draggBoxHeight = window.innerHeight - 309 + "px";
    this.$nextTick(() => {
      this.editBoxHeight =
        document.getElementsByClassName("editQuotationBox")[0].clientHeight -
        67 +
        "px"; //减去头部高度
    });
    this.init();
  },
  methods: {
    init() {
      if (this.manualForm.pricebookId !== "") {
        this.getEditQuoteLineInfo();
      } else {
        this.checkPriceBook();
      }
    },
    // 校验报价单价格手册
    checkPriceBook() {
      this.manualVisible = false;
      quotationApi.checkPriceBook({ id: this.id }).then((res) => {
        //如果pricebookid为空才选价格手册
        if (res.data && res.data.pricebookid === "") {
          this.getPriceBook();
        } else {
          this.manualForm.pricebookId = res.data.pricebookid;
          this.getEditQuoteLineInfo();
        }
      });
    },
    //获取价格手册
    getPriceBook() {
      quotationApi.getPriceBook().then((res) => {
        this.pricebooks = res.data;
        this.manualVisible = true;
      });
    },
    // 获取编辑报价行信息
    getEditQuoteLineInfo() {
      this.clear();
      this.loading = true;

      //获取编辑报价行信息
      quotationApi
        .getEditQuoteLineInfo({ id: this.id })
        .then((res) => {
          //获取币种
          this.currency = res.data.quoteJson[0].currency;
          // 处理报价单和报价行组表单信息
          this.quoteFieldList = this.resetFormAttr(res.data.quoteFieldList);
          let rightArr = res.data.quoteLineGroupFieldList.filter((val) => {
            return (
              val.apiname !== "name" &&
              val.apiname !== "description" &&
              val.apiname !== "customertotal"
            );
          });
          this.quoteLineGroupFieldList = this.resetFormAttr(rightArr);

          // 处理报价行表头信息
          if (
            res.data.quoteLineFieldList !== undefined &&
            res.data.quoteLineFieldList.length > 0
          ) {
            this.handleQuoteLineFieldList(
              // 处理报价行展示字段
              this.showQuoteLineFieldList,
              res.data.quoteLineFieldList,
              this.quoteLineFieldList
            );
            this.handleQuoteLineFieldList(
              this.showMoreQuoteLineFieldList,
              res.data.quoteLineFieldList,
              this.moreQuoteLineFieldList
            );
          }

          // 处理报价行数据 - 有分组
          if (
            res.data.quoteLineGroupJson &&
            res.data.quoteLineGroupJson.length > 0
          ) {
            if (res.data.quoteLineGroupJson.length > 1) {
              this.isBatchCopy = true; //批量复制按钮
            } else {
              this.isBatchCopy = false;
            }
            this.lineGroup = res.data.quoteLineGroupJson;
            this.lineGroup.forEach((val, index) => {
              this.resetQuoteLineIndex(val.quoteLineJson);
              val.buttonPopover = false;
              val.buttonArr = this.buttonArr;
              // 报价行组名称和描述编辑状态
              this.$set(val, "isExhibitionName", false);
              this.$set(val, "isExhibitionText", false);
              this.$set(val, "numbers", index);
              val.quoteLineGroupFieldList = this.quoteLineGroupFieldList;
              if (val.description === null) {
                val.description = this.$i18n.t(
                  "label.the.extra.reward.group.describes"
                );
              }

              // 处理数字类型字段默认不展示0
              this.quoteLineGroupFieldList.forEach((field) => {
                if (
                  (field.fieldtype === "c" || field.fieldtype === "P") &&
                  val[field.apiname] === null
                ) {
                  val[field.apiname] = undefined;
                }
              });
            });
            this.addNum = this.lineGroup.length;
          } else {
            // 无分组
            this.isBatchCopy = false; //批量复制按钮
            this.lineGroup = [];
            this.quoteLineList = res.data.quoteLineJson;
            this.resetQuoteLineIndex(this.quoteLineList);
            this.addNum = 0;
          }
          if (res.data.quoteJson && res.data.quoteJson.length > 0) {
            this.quoteInfo = res.data.quoteJson[0];
            //存储报价编号
            this.quotationNumber = res.data.quoteJson[0].numbers;
            // 处理数字类型字段默认不展示0
            this.quoteFieldList.forEach((field) => {
              if (
                (field.fieldtype === "c" || field.fieldtype === "P") &&
                this.quoteInfo[field.apiname] === null
              ) {
                this.quoteInfo[field.apiname] = undefined;
              }
            });
          }
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清除数据
    clear() {
      this.checkedIds = [];
      this.quoteLineList = [];
      this.quoteFieldList = [];
      this.quoteLineFieldList = [];
      this.moreQuoteLineFieldList = [];
      this.quoteLineGroupFieldList = [];
      this.deleteGroupIds = [];
      this.cancelGroupIds = [];
    },
    // 重置表单信息
    resetFormAttr(formAttr) {
      if (formAttr === undefined || formAttr.length < 1) {
        return [];
      } else {
        formAttr.forEach((attr) => {
          attr.prop = attr.apiname;
          attr.required = false;
          attr.type = INPUTTYPE[attr.fieldtype];
          attr.colNum = 4;
          attr.labelWidth = 180;
          if (attr.type === "number" || attr.type === "percent-number") {
            // 最大/小值小数
            let decimal =
              attr.decimalplaces !== undefined && attr.decimalplaces !== null
                ? 1 / Math.pow(10, Number(attr.decimalplaces))
                : 0.01;
            attr.precision =
              attr.decimalplaces !== undefined && attr.decimalplaces !== null
                ? Number(attr.decimalplaces)
                : 2;
            attr.valueInterval = {
              min:
                attr.fieldLength !== undefined && attr.fieldLength !== null
                  ? 0 - Math.pow(10, Number(attr.fieldLength)) + decimal
                  : 0 - Math.pow(10, 18) + decimal,
              max:
                attr.fieldLength !== undefined && attr.fieldLength !== null
                  ? Math.pow(10, Number(attr.fieldLength)) - decimal
                  : Math.pow(10, 18) - decimal,
            };
          }
        });

        return formAttr;
      }
    },
    // 处理报价行展示字段
    handleQuoteLineFieldList(showFieldList, fieldList, lineFieldList) {
      showFieldList.forEach((field) => {
        let quoteLine = fieldList.find((quoteLine) => {
          return quoteLine.apiname === field;
        });
        if (quoteLine !== undefined) {
          quoteLine.prop = quoteLine.apiname;
          quoteLine.type = INPUTTYPE[quoteLine.fieldtype];
          quoteLine.width = "calc((100% - 210px) / 7)";
          // 数量、列表单价、额外折扣字段可编辑
          if (
            quoteLine.prop === "quantity" ||
            quoteLine.prop === "listprice" ||
            quoteLine.prop === "additionaldiscount"
          ) {
            quoteLine.editable = true;
          } else {
            quoteLine.editable = false;
          }
          lineFieldList.push(quoteLine);
        }
      });
    },
    // 设置千分位样式  dj
    // thousands(num) {
    //   return (
    //     num &&
    //     (num.toString().indexOf(".") != -1
    //       ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    //           return $1 + ",";
    //         })
    //       : num.toString().replace(/(\d)(?=(\d{3}))/g, function ($0, $1) {
    //           return $1 + ",";
    //         }))
    //   );
    // },
    // 千位分隔符转换成正常数字  dj
    StrToNumber(obj) {
      var reg = /^[0-9]+.?[0-9]*$/; //验证字符串是否是数字
      if (!reg.test(obj)) {
        //为字符串 则判断是否存在','
        if ((obj + "").replace(/^\s\s*/, "").replace(/\s\s*$/, "") == "") {
          return 0.0;
        }
        if (obj == undefined) {
          return 0.0;
        }
        if ((obj || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")) {
          obj = obj.replace(/,/gi, "");
          return obj;
        }
      }
      return obj;
    },

    // 重置报价行序号
    resetQuoteLineIndex(quoteLines, preIndex) {
      this.index = preIndex || 1;
      quoteLines.forEach((line) => {
        this.$set(line, "index", this.index);
        this.index += 1;
        // 递归子级
        if (line.childproduct && line.childproduct.length > 0) {
          this.resetQuoteLineIndex(line.childproduct, this.index);
        }
      });
    },
    // 修改列表值
    modify(groupIndex, lineIndex, prop, inputNumber) {
      // 无分组
      if (this.lineGroup.length === 0) {
        this.modifyQuoteLine(this.quoteLineList, lineIndex, prop, inputNumber);
      } else {
        // 有分组
        this.lineGroup.forEach((group, groupIdx) => {
          if (
            groupIdx === groupIndex &&
            group.quoteLineJson &&
            group.quoteLineJson.length > 0
          ) {
            this.modifyQuoteLine(
              group.quoteLineJson,
              lineIndex,
              prop,
              inputNumber
            );
          }
        });
      }
    },
    //保留两位小数
    cutOutNum(num, decimals) {
      if (isNaN(num) || (!num && num !== 0)) {
        return "0.00";
      }
      // 默认为保留的小数点后两位
      let dec = decimals ? decimals : 2;
      let tempNum = Number(num);
      let pointIndex = String(tempNum).indexOf(".") + 1; // 获取小数点的位置 + 1
      let pointCount = pointIndex ? String(tempNum).length - pointIndex : 0; // 获取小数点后的个数(需要保证有小数位)
      // 源数据为整数或者小数点后面小于decimals位的作补零处理
      if (pointIndex === 0 || pointCount <= dec) {
        let tempNumA = tempNum;
        if (pointIndex === 0) {
          tempNumA = `${tempNumA}.`;
          for (let index = 0; index < dec - pointCount; index++) {
            tempNumA = `${tempNumA}0`;
          }
        } else {
          for (let index = 0; index < dec - pointCount; index++) {
            tempNumA = `${tempNumA}0`;
          }
        }
        return tempNumA;
      }
      let realVal = "";
      // 截取当前数据到小数点后decimals位
      realVal = `${String(tempNum).split(".")[0]}.${String(tempNum)
        .split(".")[1]
        .substring(0, dec)}`;
      // 判断截取之后数据的数值是否为0
      if (realVal == 0) {
        realVal = 0;
      }
      return realVal;
    },
    // 递归查找数据进行修改
    modifyQuoteLine(quoteLineList, lineIndex, prop, inputNumber) {
      quoteLineList.forEach((quoteLine) => {
        if (quoteLine.index === lineIndex) {
          // quoteLine[prop] = this.cutOutNum(inputNumber);
          // 内联编辑输入框中内容 转 千分位  dj
          quoteLine[prop] = this.cutOutNum(inputNumber);

          if (prop !== "discount" && prop !== "additionaldiscount") {
            this.$set(quoteLine, `${prop}edit`, "true");
          } else {
            this.$set(quoteLine, `additionaldiscountedit`, "true");
          }
          return;
        }
        // 考虑捆绑包
        if (quoteLine.childproduct && quoteLine.childproduct.length > 0) {
          this.modifyQuoteLine(
            quoteLine.childproduct,
            lineIndex,
            prop,
            inputNumber
          );
        }
      });
    },
    // 快速保存
    quicksave() {
      this.save("quicksave");
    },
    // 保存、快速保存
    save(flag) {
      this.loading = true;
      let params = this.getParams("save");

      quotationApi
        .save(params)
        .then((res) => {
          if (res.result) {
            this.$message.success(this.$i18n.t("savesuccess"));
            // 清空选中数据
            this.checked = [];
            this.checkedIds = [];
            if (flag !== "quicksave") {
              this.$router.push(`/commonObjects/detail/${this.id}/DETAIL`);
            } else {
              this.getEditQuoteLineInfo();
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 重新计算
    recalculate() {
      this.checkedIds = [];
      let params = this.getParams("recalculate");
      this.compute(params);
    },
    compute(params) {
      this.loading = true;
      this.quoteInfo = {};

      quotationApi
        .compute(params)
        .then((res) => {
          // 清空选中数据
          this.checked = [];
          this.checkedIds = [];
          this.lineGroup = [];
          this.quoteLineList = [];

          this.$nextTick(() => {
            // 有报价行组
            if (
              res.data.quoteLineGroupList &&
              res.data.quoteLineGroupList.length > 0
            ) {
              if (res.data.quoteLineGroupList.length > 1) {
                this.isBatchCopy = true; //批量复制按钮
              } else {
                this.isBatchCopy = false;
              }
              this.lineGroup = res.data.quoteLineGroupList;
              this.lineGroup.forEach((val, index) => {
                this.resetQuoteLineIndex(val.quoteLineJson);
                val.buttonPopover = false;
                val.buttonArr = this.buttonArr;
                // 报价行组名称和描述编辑状态
                this.$set(val, "isExhibitionName", false);
                this.$set(val, "isExhibitionText", false);
                this.$set(val, "numbers", index);
                val.quoteLineGroupFieldList = this.quoteLineGroupFieldList;
                if (val.description === null) {
                  val.description = this.$i18n.t(
                    "label.the.extra.reward.group.describes"
                  );
                }

                // 处理数字类型字段默认不展示0
                this.quoteLineGroupFieldList.forEach((field) => {
                  if (
                    (field.fieldtype === "c" || field.fieldtype === "P") &&
                    val[field.apiname] === null
                  ) {
                    val[field.apiname] = undefined;
                  }
                });
              });
            } else {
              // 无分组
              // this.quoteLineList = []
              this.quoteLineList = res.data.quoteLineList;
              this.resetQuoteLineIndex(this.quoteLineList);
            }
            // 报价单信息
            if (res.data.quoteList && res.data.quoteList.length > 0) {
              this.quoteInfo = res.data.quoteList[0];
              // 处理数字类型字段默认不展示0
              this.quoteFieldList.forEach((field) => {
                if (
                  (field.fieldtype === "c" || field.fieldtype === "P") &&
                  this.quoteInfo[field.apiname] === null
                ) {
                  this.quoteInfo[field.apiname] = undefined;
                }
              });
            }
            this.pageType = "quote";
            this.$nextTick(() => {
              this.loading = false;
            });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取快速保存、保存、重新计算接口参数
    getParams(flag) {
      let quoteJson = [];
      let quoteLine = [];
      let quoteLineGroupJson = [];
      quoteJson.push({
        dqrq: this.quoteInfo.dqrq,
        currency: this.currency,
        startdate: this.quoteInfo.startdate,
        customerdiscount: this.quoteInfo.customerdiscount
          ? this.quoteInfo.customerdiscount.toString()
          : this.quoteInfo.customerdiscount,
        targetcustomeramount: this.quoteInfo.targetcustomeramount
          ? this.quoteInfo.targetcustomeramount.toString()
          : this.quoteInfo.targetcustomeramount,
        numbers: this.quotationNumber,
      });
      quoteJson[0].id = this.id;
      quoteJson[0].pricebook2id = this.manualForm.pricebookId;
      // 有分组
      if (this.lineGroup && this.lineGroup.length > 0) {
        this.lineGroup.forEach((group, groupIndex) => {
          let groupInfo = {
            numbers: groupIndex + 1,
            name: group.name, // 名称
            description: group.description, // 描述
            enddate: group.enddate, // 订阅结束日期
            startdate: group.startdate, // 订阅开始日期
            subscriptionterm: group.subscriptionterm, // 订阅期限
            additionaldiscountrate: group.additionaldiscountrate
              ? group.additionaldiscountrate.toString()
              : group.additionaldiscountrate, // 额外折扣
            targetcustomeramount: group.targetcustomeramount
              ? group.targetcustomeramount.toString()
              : group.targetcustomeramount, // 客户目标价格
            customertotal: group.customertotal
              ? group.customertotal.toString()
              : group.customertotal, // 小计
          };
          //有报价行组id时才传
          if (group.id) {
            groupInfo.id = group.id;
          }
          groupInfo.quoteLineJson = this.getQuoteLineList(
            group.quoteLineJson,
            flag
          );
          quoteLineGroupJson.push(groupInfo);
        });
      } else {
        // 无分组
        quoteLine = this.getQuoteLineList(this.quoteLineList, flag);
      }

      let params = {};

      if (this.lineGroup && this.lineGroup.length > 0) {
        params = {
          quoteJson: JSON.stringify(quoteJson),
          quoteLineGroupJson: JSON.stringify(quoteLineGroupJson),
          isCancelGroup:
            this.cancelGroupIds && this.cancelGroupIds.length > 0
              ? "true"
              : "false",
          deleteGroupIds: this.deleteGroupIds.join(","),
        };
      } else {
        params = {
          quoteJson: JSON.stringify(quoteJson),
          quoteLineJson: JSON.stringify(quoteLine),
          isCancelGroup:
            this.cancelGroupIds && this.cancelGroupIds.length > 0
              ? "true"
              : "false",
          deleteGroupIds: this.deleteGroupIds.join(","),
        };
      }
      return params;
    },
    // 深拷贝方法
    deepClone(target) {
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(this.deepClone(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = this.deepClone(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    // 获取报价行数据
    getQuoteLineList(quoteLineList, flag) {
      let quoteLines = [];
      if (quoteLineList && quoteLineList.length > 0) {
        quoteLineList.forEach((line) => {
          if (flag === "save") {
            let quote = {
              id: line.id,
              product: line.product, // 产品
              quantity: line.quantity
                ? this.StrToNumber(line.quantity) // 数量千分位样式 转成 数字  dj
                : line.quantity, // 数量
              listprice: line.listprice
                ? this.StrToNumber(line.listprice) // 列表单价千分位样式 转成 数字  dj
                : line.listprice, // 列表单价
              discount: line.discount
                ? line.discount.toString()
                : line.discount, // 报价行客户额外折扣（%）
              additionaldiscountamount: line.additionaldiscountamount
                ? this.StrToNumber(line.additionaldiscountamount) // 额外折扣千分位样式 转成 数字  dj
                : line.additionaldiscountamount, // 报价行客户额外折扣（元）
            };
            // quantitysetup有值的时候传quantitysetup，没有的时候不传
            if (line.quantitysetup) {
              quote.quantitysetup = line.quantitysetup
                ? this.StrToNumber(line.quantitysetup)
                : line.quantitysetup;
            }
            // 如果有产品选项id,添加属性并保存
            if (
              line.productoption !== undefined &&
              line.productoption !== null
            ) {
              quote.productoption = line.productoption;
            }
            // 考虑捆绑包情况
            if (line.childproduct && line.childproduct.length > 0) {
              quote.childproduct = this.getQuoteLineList(
                line.childproduct,
                flag
              );
            }
            quoteLines.push(quote);
          } else {
            let quote = line;
            // 数字类型字段转字符串
            for (const key in quote) {
              // 千分位样式转正常数字 dj
              // if(key== 'quantity' || key== 'listprice' || key== 'additionaldiscountamount'){
              //   quote[key] = this.StrToNumber(quote[key])
              // }

              quote[key] =
                key !== "childproduct" && quote[key]
                  ? quote[key].toString()
                  : quote[key];
            }
            // 考虑捆绑包情况
            if (line.childproduct && line.childproduct.length > 0) {
              quote.childproduct = this.getQuoteLineList(
                line.childproduct,
                flag
              );
            }
            quoteLines.push(quote);
          }
        });
      }

      return quoteLines;
    },
    //拖拽报价行（根据下标匹配对应的报价行数据）
    draggleData(fromIndex, toIndex, cloneIndex) {
      //报价行组名称
      let toName = "";
      //报价行组id
      let toId = "";
      //获取拖拽到的报价行组id,name
      this.lineGroup.forEach((val) => {
        if (val.numbers == toIndex) {
          toName = val.name;
          toId = val.id;
        }
      });
      //获取拖拽的报价行信息，并进行报价行组id和名称，及下标的替换
      this.lineGroup.forEach((val) => {
        if (val.numbers == fromIndex) {
          val.quoteLineJson.forEach((childVal) => {
            if (childVal.index == cloneIndex) {
              this.$set(childVal, "numbers", toIndex);
              this.$set(childVal, "quotelinegroup", toId ? toId : "");
              this.$set(childVal, "quotelinegroupccname", toName);
              this.childFun(childVal, toIndex);
            }
          });
        }
      });
      //进行报价行组报价行信息的重新赋值
      this.lineGroup.forEach((val, index) => {
        if (val.numbers == fromIndex) {
          this.$set(
            val,
            "quoteLineJson",
            this.$refs.quotationTable[index].tableData
          );
        }
        if (val.numbers == toIndex) {
          this.$set(
            val,
            "quoteLineJson",
            this.$refs.quotationTable[index].tableData
          );
        }
        this.resetQuoteLineIndex(this.lineGroup[index].quoteLineJson);
      });
      this.recalculate();
    },
    //递归实现子孙报价行组id和报价行组name，下标更改
    childFun(firstArr, toIndex) {
      firstArr.childproduct &&
        firstArr.childproduct.forEach((childVal) => {
          this.$set(childVal, "numbers", toIndex);
          if (firstArr.quotelinegroup) {
            this.$set(childVal, "quotelinegroup", firstArr.quotelinegroup);
            this.$set(
              childVal,
              "quotelinegroupccname",
              firstArr.quotelinegroupccname
            );
          }
          this.childFun(childVal, toIndex);
        });
    },
    // 取消
    cancel() {
      this.$router.push(`/commonObjects/detail/${this.id}/DETAIL`);
    },
    // 添加产品
    addProduct() {
      if (this.$refs.quotationTable) {
        this.$refs.quotationTable.checkAll = false;
      }
      this.pageType = "chooseProduct";
      this.$refs.chooseProducts.pageType = "chooseProduct";
      this.$refs.chooseProducts.clearBtn();
    },
    // 选中/取消选中数据
    checkLine(checked, lineData, groupIndex) {
      if (checked) {
        if (
          lineData.id !== undefined &&
          lineData.id !== null &&
          lineData.id !== "" &&
          this.checkedIds.indexOf(lineData.id) === -1
        ) {
          this.checkedIds.push(lineData.id);
        }
        this.checked.push({
          index: lineData.index,
          groupIndex: groupIndex,
        });

        this.lineGroup[groupIndex].quoteLineJson.forEach((item) => {
          if (lineData.index === item.index) {
            this.groupArr.push(this.deepClone(item));
          }
        });
      } else {
        // for (var i = 0; i < this.groupArr.length; i++) {
        //   //取消勾选从数组里移除
        //   if (list.productcode == this.groupArr[i].productcode) {
        //     this.groupArr.splice(i, 1)
        //   }
        // }
        let index = this.checkedIds.indexOf(lineData.id);
        if (index !== -1) {
          this.checkedIds.splice(index, 1);
        }
        this.checked.forEach((checkLine, index) => {
          if (
            checkLine.index === lineData.index &&
            checkLine.groupIndex === groupIndex
          ) {
            this.checked.splice(index, 1);
          }
        });
      }
    },
    // 批量删除报价行
    deleteQuoteLines() {
      // 判断删除数据是否为空
      if (this.checked.length < 1) {
        this.$message.warning(this.$i18n.t("label.quotation.deleteTip"));
      } else {
        this.dialogVisible = true;
      }
    },
    // 通过下标删除数据
    bacthDeleteByIndex() {
      // 有报价行组
      if (this.lineGroup && this.lineGroup.length > 0) {
        this.checked.forEach((checkLine) => {
          this.lineGroup.forEach((group, groupIdx) => {
            if (groupIdx === checkLine.groupIndex) {
              this.deleteByIndex(group.quoteLineJson, checkLine.index);
            }
          });
        });
      } else {
        // 无报价行组
        this.checked.forEach((checkLine) => {
          this.deleteByIndex(this.quoteLineList, checkLine.index);
        });
      }
      this.$nextTick(() => {
        this.recalculate();
      });
    },
    // 确认批量删除
    confirmDel() {
      // 删除单条数据
      this.dialogVisible = false;
      if (this.checkedIds.length > 0) {
        if (this.checkedIds.length === 1) {
          this.batchDelete(this.checkedIds.toString());
        } else {
          this.batchDelete(this.checkedIds.join(","));
        }
      } else {
        // 没有要通过接口删除的数据
        this.bacthDeleteByIndex();
      }
    },
    // 批量删除报价行
    batchDelete(ids) {
      this.loading = true;
      quotationApi
        .deleteQuoteLine({ ids: ids })
        .then((res) => {
          if (res.result) {
            this.dialogVisible = false;
            this.$message.success(this.$i18n.t("label.ems.delete.success"));
            this.bacthDeleteByIndex();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 删除单个报价行
    deleteQuoteLine(groupIndex, lineIndex) {
      // 有报价行组
      if (this.lineGroup && this.lineGroup.length > 0) {
        this.lineGroup.forEach((group, groupIdx) => {
          if (groupIdx === groupIndex) {
            this.deleteByIndex(group.quoteLineJson, lineIndex);
            return;
          }
        });
      } else {
        // 无报价行组
        this.deleteByIndex(this.quoteLineList, lineIndex);
      }
      this.$nextTick(() => {
        this.recalculate();
      });
    },
    // 删除指定位置的报价行
    deleteByIndex(quoteLines, lineIndex) {
      quoteLines.forEach((line, idx) => {
        if (line.index === lineIndex) {
          quoteLines.splice(idx, 1);
          return;
        }
      });
    },
    // 添加loading
    addLoading() {
      this.loading = !this.loading;
    },
    //小扳手跳配置页面
    configQuotation(list, groupIndex) {
      this.listIndex = list.index;
      this.groupIndex = groupIndex;
      this.pageType = "configQuotation";
      this.pageTypes = "quote";
      this.parentData.push({
        id: list.id,
        listprice: list.listprice,
        product: list.product,
        quantity: list.quantity,
      });
      localStorage.setItem("productId", JSON.stringify(list.product));
      this.quotelineid = list.id;
      if (list.childproduct) {
        //需要回显的数据传给配置页面
        this.configEcho = list.childproduct;
      } else {
        this.configEcho = [];
      }
      this.$refs.configQuotation.isNav();
    },
    //在复制报价行调用成功前只执行一次
    Throttles(id) {
      if (this.throttle) {
        return false;
      }
      this.throttle = true;
      this.copyQuotation(id);
    },
    Throttless() {
      if (this.throttle) {
        return false;
      }
      this.throttle = true;
      this.copyQuotationALL();
    },
    //批量复制
    copyQuotationALL() {
      if (this.groupArr.length < 1) {
        this.$message({
          type: "warning",
          message: this.$i18n.t("label.attendance.onrecord"),
        });
        this.throttle = false;
      } else {
        this.handleCopy = true;
        this.throttle = false;
        this.generateData();
        this.confirmCopyy();
      }
    },
    //获取元素下标
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    //递归删除childproduct id
    delChildren(data) {
      const del = function (list) {
        if (list.childproduct) {
          list.childproduct.forEach(function (row) {
            delete row.id;
            if (row.childproduct) {
              del(this.$delete(row.childproduct, "id"));
            }
          });
        }
      };
      del(data);
    },
    //复制报价行
    copyQuotation(list) {
      //有报价行组根据报价行组下标进行判断复制，否则直接复制
      if (this.lineGroup.length > 0) {
        for (let i = 0; i < this.lineGroup.length; i++) {
          //设置报价行组下标
          this.lineGroup[i].groupIdx = i;
          this.lineGroup[i].quoteLineJson.forEach((item) => {
            item.groupIdx = i;
          });
        }
        this.lineGroup.forEach((item) => {
          if (list.groupIdx === item.groupIdx) {
            let index = this.getArrayIndex(item.quoteLineJson, list);
            item.quoteLineJson.splice(index, 0, this.deepClone(list)); //数据深拷贝防止数据一起删除
            this.$delete(item.quoteLineJson[index + 1], "id");
            this.delChildren(list);
            this.throttle = false;
            this.recalculate();
          }
        });
      } else {
        let index = this.getArrayIndex(this.quoteLineList, list);
        this.quoteLineList.splice(index, 0, this.deepClone(list));
        this.$delete(list, "id");
        this.throttle = false;
        this.recalculate();
      }
    }, //穿梭框左右互传
    generateData() {
      this.groupData = [];
      for (let i = 0; i < this.lineGroup.length; i++) {
        //设置报价行组下标
        this.lineGroup[i].groupIdx = i;
      }
      this.lineGroup.forEach((item) => {
        this.groupData.push({
          label: item.name,
          key: item.groupIdx,
          groupid: item.groupIdx,
        });
      });
      return this.groupData;
    },
    confirmCopyy() {
      for (let i = 0; i < this.groupArr.length; i++) {
        //设置报价行组下标
        this.groupArr[i].groupIdx = i;
      }
      if (this.groupValue.length > 0) {
        this.groupValue.forEach((item) => {
          this.groupArr.forEach((ite) => {
            this.lineGroup[item].quoteLineJson.push(this.deepClone(ite));
            this.$delete(ite, "id");
          });
        });
        this.throttle = false;
        this.handleCopyy();
        this.recalculate();
      } else {
        this.$message({
          type: "warning",
          message: this.$i18n.t("label.select.one.group"),
        });
        this.throttle = false;
      }
    },
    // 关闭弹窗
    // handleClose() {
    //   this.dialogVisible = false;
    // },
    handleCopyy() {
      this.handleCopy = false;
      this.loading = false;
    },
    // 添加报价行组
    addLineGroup() {
      //深拷贝报价行组、报价行
      this.copyLineGroup = JSON.parse(JSON.stringify(this.lineGroup));
      this.copyQuoteLineList = JSON.parse(JSON.stringify(this.quoteLineList));
      //报价单名称序号
      this.addNum++;
      if (this.addNum !== 1) {
        this.copyQuoteLineList = [];
      }
      this.copyLineGroup.push({
        //报价行组名称
        name: this.$i18n.t("label.the.reward.group") + this.addNum,
        numbers: this.addNum - 1,
        isExhibitionName: false,
        //添加报价行组描述
        description: this.$i18n.t("label.the.extra.reward.group.describes"),
        isExhibitionText: false,
        //报价行组按钮
        buttonArr: [
          {
            name: "add",
            label: this.$i18n.t("vue_label_opportunity_product_addproduct"), //'添加产品',
          },
          {
            name: "copy",
            label: this.$i18n.t("label.copying.reward.groups"), //'复制报价行组'
          },
          {
            name: "delete",
            label: this.$i18n.t("label.delete.reward.groups"), //'删除报价行组'
          },
          // {
          //   name:'reset',
          //   label: this.$i18n.t('label.been.detained'), //'重置折扣'
          // },
        ],
        quoteLineGroupFieldList: this.quoteLineGroupFieldList,
        quoteLineJson: this.copyQuoteLineList,
        customertotal: this.customertotal,
      });
      let params = this.getParamsCopy(
        this.copyLineGroup,
        this.copyQuoteLineList
      );
      this.compute(params);
    },
    // 取消报价行组
    cancelLineGroup() {
      this.addNum = 0;
      this.groupIndex = "";
      this.copyQuoteLineList = [];
      this.isBatchCopy = false;
      this.copyLineGroup = JSON.parse(JSON.stringify(this.lineGroup));
      this.lineGroup.forEach((val) => {
        if (val.id) {
          this.cancelGroupIds.push(val.id);
        }
        val.quoteLineJson.forEach((childVal) => {
          this.copyQuoteLineList.push(childVal);
        });
      });
      this.copyLineGroup = [];
      let params = this.getParamsCopy(
        this.copyLineGroup,
        this.copyQuoteLineList
      );
      this.compute(params);
    },
    // 关闭选择产品价格手册弹窗
    submitForm() {
      this.$refs.pricebookForm.validate((valid) => {
        if (valid) {
          this.manualVisible = false;
          this.getEditQuoteLineInfo();
        } else {
          return false;
        }
      });
    },
    // 取消选择价格手册弹窗 - 返回上一页面
    manualClose() {
      this.$router.go(-1);
    },
    //添加产品
    quotationShowBtn(groupIndex) {
      this.groupIndex = groupIndex;
      this.pageType = "chooseProduct";
      this.$refs.chooseProducts.pageType = "chooseProduct";
      this.$refs.chooseProducts.clearBtn();
    },
    //报价行组其他信息
    quotationMoreBtn(item, groupIndex, groupItem) {
      if (item.name === "copy") {
        //复制报价行组
        let copyGroupItem = JSON.parse(JSON.stringify(groupItem));
        delete copyGroupItem.id;
        copyGroupItem.quoteLineJson.forEach((val) => {
          delete val.id;
          this.deleteIdFun(val);
        });
        this.lineGroup.push(copyGroupItem);
        this.recalculate();
      } else if (item.name === "delete") {
        // '确定删除 ' + groupItem.name + ' 报价行组吗？删除后，该组内的报价行将会被一起删除。'
        this.$confirm(
          this.$i18n.t("label.quote.line.group.", {
            groupName: groupItem.name,
          }),
          this.$i18n.t("label.emailtocloudcc.tip"),
          {
            confirmButtonText: this.$i18n.t("label.confirm"), //'确定',
            cancelButtonText: this.$i18n.t("label.ems.cancel"), //'取消',
            type: "warning",
          }
        )
          .then(() => {
            //获取删除组所有id
            if (groupItem.id) {
              this.deleteGroupIds.push(groupItem.id);
            }
            //深拷贝报价行组、报价行
            this.copyLineGroup = JSON.parse(JSON.stringify(this.lineGroup));
            this.copyQuoteLineList = JSON.parse(
              JSON.stringify(this.quoteLineList)
            );
            this.copyLineGroup = this.copyLineGroup.filter((val, index) => {
              return index !== groupIndex;
            });
            let params = this.getParamsCopy(
              this.copyLineGroup,
              this.copyQuoteLineList
            );
            this.compute(params);
            this.addNum = this.copyLineGroup.length;
          })
          .catch(() => {});
      }
      groupItem.buttonPopover = false;
    },
    //递归删除复制后的报价行组内报价行所有id
    deleteIdFun(firstArr) {
      firstArr.childproduct &&
        firstArr.childproduct.forEach((childVal) => {
          delete childVal.id;
          this.deleteIdFun(childVal);
        });
    },
    //报价行名称显示
    mouseEnterName(valName) {
      if (
        document.getElementsByClassName("editIconName" + valName) &&
        document.getElementsByClassName("editIconName" + valName)[0]
      ) {
        document.getElementsByClassName(
          "editIconName" + valName
        )[0].style.display = "";
      }
    },
    //报价行名称隐藏
    mouseLeaveName(valName) {
      if (
        document.getElementsByClassName("editIconName" + valName) &&
        document.getElementsByClassName("editIconName" + valName)[0]
      ) {
        document.getElementsByClassName(
          "editIconName" + valName
        )[0].style.display = "none";
      }
    },
    // 编辑报价行名称
    editNameBtn(groupItem) {
      groupItem.isExhibitionName = true;
    },
    //报价行描述显示
    mouseEnterText(valName) {
      if (
        document.getElementsByClassName("editIconText" + valName) &&
        document.getElementsByClassName("editIconText" + valName)[0]
      ) {
        document.getElementsByClassName(
          "editIconText" + valName
        )[0].style.display = "";
      }
    },
    //报价行描述隐藏
    mouseLeaveText(valName) {
      if (
        document.getElementsByClassName("editIconText" + valName) &&
        document.getElementsByClassName("editIconText" + valName)[0]
      ) {
        document.getElementsByClassName(
          "editIconText" + valName
        )[0].style.display = "none";
      }
    },
    // 编辑报价行描述
    editTextBtn(groupItem) {
      groupItem.isExhibitionText = true;
    },
    //保存报价行名称
    infoBtn(groupItem) {
      if (groupItem.name === "") {
        // 报价行组1
        groupItem.name = this.$i18n.t("label.the.reward.group") + 1;
      }
      if (groupItem.description === "") {
        groupItem.description = this.$i18n.t(
          "label.the.extra.reward.group.describes"
        );
      }
      groupItem.isExhibitionName = false;
      groupItem.isExhibitionText = false;
    },
    //导航条定位
    jump(subscript) {
      this.isactive = subscript;
      let editContent = document.querySelector(".edit_content");
      let lineBox = document.getElementsByClassName("line_box");
      let quotationInfoBox = document.querySelector(".quotationInfoBox");
      if (subscript === 0) {
        editContent.scrollTo(0, 0);
      } else {
        editContent.scrollTo(
          0,
          lineBox[subscript].offsetTop - quotationInfoBox.offsetTop - 9
        );
      }
    },
    //选择产品取消操作
    // goBack() {
    //   this.pageType = "quote";
    // },
    // 校验配置页面选中的产品是否符合规则
    configSave(quotionData, groupIndex, newOptionArr, listIndex) {
      let quoteJson = [];
      let quoteLine = [];
      quoteJson.push({
        dqrq: this.quoteInfo.dqrq,
        currency: this.currency,
        startdate: this.quoteInfo.startdate,
        customerdiscount: this.quoteInfo.customerdiscount
          ? this.quoteInfo.customerdiscount.toString()
          : this.quoteInfo.customerdiscount,
        targetcustomeramount: this.quoteInfo.targetcustomeramount
          ? this.quoteInfo.targetcustomeramount.toString()
          : this.quoteInfo.targetcustomeramount,
      });
      quoteJson[0].id = this.id;
      quoteJson[0].pricebook2id = this.manualForm.pricebookId;
      quotionData.forEach((line) => {
        let quote = line;
        // 数字类型字段转字符串
        for (const key in quote) {
          // 千分位样式转正常数字 dj
          if (
            key == "quantity" ||
            key == "listprice" ||
            key == "additionaldiscountamount"
          ) {
            quote[key] = this.StrToNumber(quote[key]);
          }
          quote[key] =
            key !== "childproduct" && quote[key]
              ? quote[key].toString()
              : quote[key];
        }
        quoteLine.push(quote);
      });
      let params = {
        quoteJson: JSON.stringify(quoteJson),
        quoteLineJson: JSON.stringify(quoteLine),
      };
      quotationApi
        .compute(params)
        .then((res) => {
          this.pageType = "quote";
          if (this.pageTypes === "chooseProduct") {
            if (
              res.data.quoteLineGroupList === "" ||
              res.data.quoteLineGroupList === undefined ||
              res.data.quoteLineGroupList === null
            ) {
              if (groupIndex || groupIndex === 0) {
                //添加产品
                this.lineGroup[groupIndex].quoteLineJson = [];
                this.lineGroup[groupIndex].quoteLineJson =
                  res.data.quoteLineList;
                this.groupIds = groupIndex;
                //非必配产品添加子产品
                this.lineGroup[groupIndex].quoteLineJson.forEach((item) => {
                  quoteLine.forEach((ite) => {
                    if (item.product === ite.product && ite.isconfig == false) {
                      item.childproduct = ite.childproduct;
                    }
                  });
                });
              } else {
                this.quoteLineList = [];
                this.quoteLineList = res.data.quoteLineList;
                //非必配产品添加子产品
                this.quoteLineList.forEach((item) => {
                  quoteLine.forEach((ite) => {
                    if (item.product === ite.product && ite.isconfig == false) {
                      item.childproduct = ite.childproduct;
                    }
                  });
                });
                // this.$refs.configQuotation.quotionData
              }
            }
          } else {
            if (groupIndex || groupIndex === 0) {
              this.lineGroup[groupIndex].quoteLineJson.forEach((item) => {
                if (item.index == listIndex) {
                  item.childproduct = newOptionArr;
                }
              });
              // this.$refs.configQuotation.quotionData
            } else {
              this.quoteLineList.forEach((item) => {
                if (item.index == listIndex) {
                  item.childproduct = newOptionArr;
                }
              });
            }
          }
          this.recalculate();
        })
        .catch(() => {
          this.pageType = "configQuotation";
        });
    },
    // 递归千分位样式转正常数字   dj
    // thousandtonum(el) {
    //   if (el.childproduct) {
    //     el.childproduct.forEach((al) => {
    //       al.quantity = this.StrToNumber(al.quantity);
    //       al.listprice = this.StrToNumber(al.listprice);
    //       al.additionaldiscountamount = this.StrToNumber(
    //         al.additionaldiscountamount
    //       );
    //       if (al.childproduct) {
    //         this.thousandtonum(al);
    //       }
    //     });
    //   }
    // },
    // 校验选中的产品是否符合规则
    checkData(quotionData, isConfigure) {
      let idss = [];
      quotionData.forEach((item) => {
        idss.push(item.product);
      });
      idss = idss.toString();
      quotationApi.getRequiredOptions({ ids: idss }).then((res) => {
        quotionData.forEach((item) => {
          res.data.forEach((ite) => {
            if (item.product === ite.product) {
              ite.isconfig = item.isconfig;
            }
          });
        });
        quotionData = res.data;
      });
      let quoteJson = [];
      let quoteLine = [];

      quoteJson.push({
        dqrq: this.quoteInfo.dqrq,
        currency: this.currency,
        startdate: this.quoteInfo.startdate,
        customerdiscount: this.quoteInfo.customerdiscount
          ? this.quoteInfo.customerdiscount.toString()
          : this.quoteInfo.customerdiscount,
        targetcustomeramount: this.quoteInfo.targetcustomeramount
          ? this.quoteInfo.targetcustomeramount.toString()
          : this.quoteInfo.targetcustomeramount,
      });
      quoteJson[0].id = this.id;
      quoteJson[0].pricebook2id = this.manualForm.pricebookId;

      quotionData.forEach((line) => {
        let quote = line;
        // 数字类型字段转字符串
        for (const key in quote) {
          // 千分位样式转正常数字 dj
          // if(key== 'quantity' || key== 'listprice' || key== 'additionaldiscountamount'){
          //   quote[key] = this.StrToNumber(quote[key])
          // }

          quote[key] =
            key !== "childproduct" && quote[key]
              ? quote[key].toString()
              : quote[key];
        }
        quoteLine.push(quote);
      });

      let params = {
        quoteJson: JSON.stringify(quoteJson),
        quoteLineJson: JSON.stringify(quoteLine),
      };
      quotationApi
        .compute(params)
        .then((res) => {
          if (res.result) {
            if (isConfigure) {
              this.pageType = "configQuotation";
              this.pageTypes = "chooseProduct";
              this.$parent.parentData = quotionData;
              this.configEcho = null;
            } else {
              this.getAllQuotation(quotionData);
            }
          }
        })
        .catch(() => {
          this.pageType = "chooseProduct";
        });
    },
    //获取选中产品后的报价单信息
    getAllQuotation(arr) {
      //深拷贝报价行组，报价行用于校验分块定价数量
      this.copyLineGroup = JSON.parse(JSON.stringify(this.lineGroup));
      this.copyQuoteLineList = JSON.parse(JSON.stringify(this.quoteLineList));
      if (this.copyLineGroup.length > 0) {
        if (this.copyLineGroup[this.groupIndex].quoteLineJson.length === 0) {
          this.copyLineGroup[this.groupIndex].quoteLineJson = arr;
        } else {
          arr.forEach((val) => {
            this.copyLineGroup[this.groupIndex].quoteLineJson.push(val);
          });
        }
      } else {
        if (this.copyQuoteLineList.length === 0) {
          this.copyQuoteLineList = arr;
        } else {
          arr.forEach((val) => {
            this.copyQuoteLineList.push(val);
          });
        }
      }
      let params = this.getParamsCopy(
        this.copyLineGroup,
        this.copyQuoteLineList
      );
      this.compute(params);
    },
    // 用于选择产品分块定价校验、添加报价行组
    getParamsCopy(groupArr, arr) {
      let quoteJson = [];
      let quoteLine = [];
      let quoteLineGroupJson = [];

      quoteJson.push({
        dqrq: this.quoteInfo.dqrq,
        currency: this.currency,
        startdate: this.quoteInfo.startdate,
        customerdiscount: this.quoteInfo.customerdiscount
          ? this.quoteInfo.customerdiscount.toString()
          : this.quoteInfo.customerdiscount,
        targetcustomeramount: this.quoteInfo.targetcustomeramount
          ? this.quoteInfo.targetcustomeramount.toString()
          : this.quoteInfo.targetcustomeramount,
      });
      quoteJson[0].id = this.id;
      quoteJson[0].pricebook2id = this.manualForm.pricebookId;

      // 有分组
      if (groupArr && groupArr.length > 0) {
        groupArr.forEach((group) => {
          let groupInfo = {
            name: group.name, // 名称
            description: group.description, // 描述
            enddate: group.enddate, // 订阅结束日期
            startdate: group.startdate, // 订阅开始日期
            subscriptionterm: group.subscriptionterm, // 订阅期限
            additionaldiscountrate: group.additionaldiscountrate
              ? group.additionaldiscountrate.toString()
              : group.additionaldiscountrate, // 额外折扣
            targetcustomeramount: group.targetcustomeramount
              ? group.targetcustomeramount.toString()
              : group.targetcustomeramount, // 客户目标价格
            customertotal: group.customertotal
              ? group.customertotal.toString()
              : group.customertotal, // 小计
          };
          //有报价行组id时才传
          if (group.id) {
            groupInfo.id = group.id;
          }
          groupInfo.quoteLineJson = this.getQuoteLineList(
            group.quoteLineJson,
            "recalculate"
          );
          quoteLineGroupJson.push(groupInfo);
        });
      } else {
        // 无分组
        quoteLine = this.getQuoteLineList(arr, "recalculate");
      }

      let params = {};
      if (groupArr && groupArr.length > 0) {
        params = {
          quoteJson: JSON.stringify(quoteJson),
          quoteLineGroupJson: JSON.stringify(quoteLineGroupJson),
        };
      } else {
        params = {
          quoteJson: JSON.stringify(quoteJson),
          quoteLineJson: JSON.stringify(quoteLine),
        };
      }
      return params;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.padding10 {
  padding: 10px;
}

.margin10 {
  margin-right: 10px;
}

.editQuotationBox {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 0 10px;

  .main {
    border-radius: 3px;
    border: 1px solid #dedcda;
    border-bottom: none;
    background: #fff;
    height: auto;

    .top {
      width: 100%;
      height: 56px;
      background: #f5f5f5;
      border-bottom: 1px solid #dedcda;

      .left-content {
        width: 30%;

        .quotationImg {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        .quotationCode {
          color: #333;
          font-size: 12px;
          height: 16px;
          line-height: 16px;
        }

        .title {
          color: #080707;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .right-content {
        width: 70%;
        padding: 13px 10px;

        .el-button {
          padding: 8px 10px;
          color: #006dcc;
        }

        .el-button--primary {
          color: #fff;
        }
      }
    }
    .edit_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      ::v-deep .navigation {
        width: unset;
      }
      .edit_content {
        width: 100%;
        height: 100%;
        overflow: auto;
        .line_wrap {
          width: 100%;
          .line_box {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            background: #fafaf9;
            width: 100%;
            border: 1px solid #dedcda;
            .no_footer {
              width: 100%;
              height: 40px;
              line-height: 40px;
              color: #888888;
              margin-top: -80px;
              padding: 0 12px;
              box-sizing: border-box;
              font-size: 12px;
            }
            .line_group {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 10px;
              box-sizing: border-box;
              .btn_left {
                width: 50%;
                border-right: 1px solid #dedcda;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info_box {
                  max-width: 70%;
                  .first_top {
                    display: flex;
                    margin-bottom: 10px;
                    .collection {
                      margin-right: 10px;
                      margin-top: 5px;
                    }
                    div {
                      span {
                        font-size: 16px;
                        color: #888888;
                      }
                    }
                  }
                  .two_bottom {
                    margin-bottom: 0;
                    font-size: 12px;
                    color: #888888;
                  }
                }
                ::v-deep .el-button-group {
                  position: relative;
                  margin-right: 20px;
                  .moreButton {
                    margin-bottom: 0;
                    li {
                      height: 30px;
                      line-height: 30px;
                      &:hover {
                        color: #006dcc;
                      }
                    }
                  }
                  ::v-deep .btn_box {
                    height: 30px;
                    text-align: center;
                    padding: 0 15px;
                    border: 1px solid #dedcda;
                    color: #006dcc !important;
                    &:hover {
                      background: #fff;
                      border: 1px solid #dedcda;
                    }
                  }
                  span {
                    color: #006dcc;
                  }
                }
                .xiala {
                  width: 28px;
                  height: 28px;
                  border: 1px solid #dedcda;
                  border-top-right-radius: 3px;
                  border-bottom-right-radius: 3px;
                  display: inline-block;
                  background: #fff;
                  text-align: center;
                  cursor: pointer;
                  svg {
                    width: 16px;
                    margin: 5px;
                  }
                }
              }
              ::v-deep .form_right {
                width: 50%;
                .el-form {
                  .pull-left {
                    .el-form-item {
                      height: 30px;
                      .el-form-item__label {
                        line-height: 18px;
                        margin-top: 8px;
                      }
                      .el-form-item__content {
                        line-height: 30px;
                        height: 30px;
                        .el-input__icon {
                          line-height: 30px;
                        }
                      }
                      .el-input {
                        height: 30px;
                        width: 100%;
                      }
                      .el-input__inner {
                        height: 30px;
                        width: 100%;
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.totalQuotation {
  width: 100%;
  font-weight: bold;
  margin-top: 15px;
  padding-left: calc((100% - 210px) / 7 * 6 + 70px);
}

.quotationInfoBox {
  padding: 16px 10px 0 10px;
  clear: both;
  ::v-deep .tit_form {
    .pull-left {
      .el-form-item {
        height: 30px;
        .el-form-item__label {
          line-height: 18px;
          margin-top: 8px;
        }
        .el-form-item__content {
          line-height: 30px;
          height: 30px;
          .el-input__icon {
            line-height: 30px;
          }
        }
        .el-input {
          height: 30px;
          width: 100%;
        }
        .el-input__inner {
          height: 30px;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  .quotationInfoTitle {
    color: #080707;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  ::v-deep .el-form {
    width: 100%;
    float: left;
    min-height: 50px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 6px;
  }

  ::v-deep .el-input {
    height: 30px;
    width: 100%;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    width: 100%;
    text-align: left;
  }
  .no_add {
    color: #888;
    font-size: 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}

.pop_box .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.pop_box .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
  cursor: pointer;
}

.pop_box .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
