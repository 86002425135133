<!-- CPQ-选择产品 -->
<!-- 开发人员：王雪茹 -->
<template>
  <div class="objectTableMain">
    <div class="object_box" v-show="pageType === 'chooseProduct'">
      <div class="price_box">
        <div class="tit">
          <svg class="icon viewImg" aria-hidden="true">
            <use href="#icon-xuanzechanpin"></use>
          </svg>
          <div>
            <p style="font-size: 12px">{{ quotenumber }}</p>
            <p style="font-size: 18px; color: #080707">
              <!-- 选择产品 -->
              {{ $t("lable.opppdt.addpdt.title") }}
            </p>
          </div>
        </div>
        <div class="search">
          <div class="btn_group">
            <div class="shaixuan">
              <svg class="icon" aria-hidden="true" @click="isFilterBtn">
                <use href="#icon-shaixuan1"></use>
              </svg>
            </div>
            <el-button-group>
              <!-- 选择 取消-->
              <el-button size="small" type="primary" @click="choicesBtn">
                {{ $t("label.choices") }}
              </el-button>
              <el-button size="small" @click="goBack" class="cancel_btn">{{
                $t("label.emailsync.button.cancel")
              }}</el-button>
            </el-button-group>
          </div>
          <div style="width: 278px; margin-top: 10px">
            <el-input
              v-model="projectForm.productname"
              :placeholder="$t('label.searchs')"
              @change="changeKeyWord"
            ></el-input>
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-top: 2px"
              @click="changeKeyWord"
            >
              <use href="#icon-sousuo"></use>
            </svg>
          </div>
        </div>
      </div>
      <!-- 列表表头说明文字 -->
      <statistics
        :total="totalSize"
        :isQueryCount="true"
        :field="sortFieldName"
        :time-interval="timeInterval"
        :show-checked-length="false"
        :checked-length="3"
        @refresh="refresh"
      ></statistics>
      <!-- 表格 -->
      <div class="table_s">
        <table-panel
          class="text-center"
          ref="tablePanel"
          :border="true"
          :table-height="tableHeight"
          :show-index="false"
          :checked="true"
          :showLock="false"
          :total="totalSize"
          :page-size-list="pageSize"
          :table-attr="chooseArr"
          :page-obj="pageObj"
          :noDataTip="noDataTip"
          :pict-loading="pictLoading"
          @onchange="onchange"
          @refresh="refresh"
        >
        </table-panel>
        <!-- :pageNum="page" -->
      </div>
      <!-- 筛选器 -->
      <product-screening
        v-if="isFilter"
        :choose="choose"
        :projectForm="projectForm"
        :table-height="tableHeight - 50"
        :topVal="topVal"
        @filterBtn="filterBtn"
        @handleClick="handleClick"
        @clearBtn="clearBtn"
      >
      </product-screening>
    </div>
    <!-- <ConfigQuotation
      ref="configQuotation"
      v-if="pageType === 'configQuotation'"
      :pricebookid="pricebookid"
      :currency="currency"
      :id="id"
      :quoteId="quoteId"
      :groupIndex="groupIndex"
      :quotelineid="quotelineid"
      :configEcho="null"
      :parentData="parentData"
      pageType="chooseProduct"
    /> -->
  </div>
</template>

<script>
import { Statistics, TablePanel } from "@/components/index";
import productScreening from "./components/productScreening.vue";
// import ConfigQuotation from './configQuotation.vue'
import * as quotationApi from "./api.js";

export default {
  components: { Statistics, productScreening, TablePanel },
  props: {
    // 价格手册id
    pricebookid: {
      type: String,
      default: "",
    },
    // 报价单id
    id: {
      type: String,
      default: "",
    },
    // 币种
    // currency: {
    //   type: String,
    //   default: '',
    // },
    //报价行组下标
    groupIndex: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      choose: true,
      projectForm: {
        cpdm: "",
        cpxl: "",
        productname: "",
      },
      timeInterval: "",
      sortFieldName: "",
      isFilter: false,
      tableHeight: "300px",
      // dialogVisible: false,
      // title: '',
      // layout: ' sizes, prev, pager, next, jumper',
      // totalCount: 0,
      // skippageNum: 1, //页码
      pictLoading: false,
      totalSize: 0, // 总记录数
      pageObj: {
        dataList: [],
      },
      noDataTip: false,
      chooseArr: [],
      page: 1,
      pageSize: 200,
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      selectedList: [],
      quotionData: [],
      productId: "",
      // requireChecked: false, //产品必选且不可编辑
      quotenumber: "",
      // parentData: null,
      // isAddProduct: true,
      // isConfigShow: false,
      pageType: "chooseProduct",
      topVal: "95px", //筛选器top值传参
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.tableHeight =
        document.getElementsByClassName("home_content")[0].clientHeight - 215;
    });
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  methods: {
    //获取价格手册列表数据
    showProductList() {
      this.pictLoading = true;
      let data = {
        productname: this.projectForm.productname,
        cpdm: this.projectForm.cpdm,
        cpxl: this.projectForm.cpxl,
        quoteid: this.id,
        pricebookid: this.pricebookid,
        page: this.page,
        pageSize: this.pageSize,
      };
      quotationApi
        .showProductList(data)
        .then((res) => {
          if (res.data) {
            res.data.tableLabel.forEach((val) => {
              val.nameLabel = val.label;
              val.schemefieldName = val.apiname;
            });
            this.chooseArr = res.data.tableLabel;
            this.totalSize = res.data.totalSize;
            this.quotenumber = res.data.quotenumber;
            this.pictLoading = false;
            this.pageObj.dataList = res.data.productlist;
            // 是否提示无更多数据
            if (this.pageObj.dataList.length === res.data.totalCount) {
              this.noDataTip = true;
            }
          }
        })
        .catch(() => {
          this.pictLoading = false;
        });
    },
    //搜索
    changeKeyWord() {
      this.showProductList();
    },
    //筛选器开关
    isFilterBtn() {
      if (this.isFilter) {
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
    //筛选器关闭
    handleClick() {
      this.isFilter = false;
    },
    //筛选器筛选
    filterBtn() {
      this.showProductList();
    },
    //清除条件
    clearBtn() {
      this.page = 1;
      this.projectForm = {
        cpdm: "",
        cpxl: "",
        productname: "",
      };
      // 重置列表选中统计数据
      if (this.$refs.tablePanel) {
        this.$refs.tablePanel.checkedList = [];
      }
      this.showProductList();
      this.isFilter = false;
    },
    refresh() {
      this.lastRefresh = new Date();
      // 重置列表选中统计数据
      // if (this.$refs.tablePanel) {
      //   this.$refs.tablePanel.checkedList = []
      // }
      // this.pageObj.dataList.forEach(val => {
      //   delete val.checked
      //   delete val.attachChecked
      // })
      this.showProductList();
    },
    //保存
    choicesBtn() {
      if (this.selectedList && this.selectedList.length > 0) {
        //存保存报价行参数信息
        this.quotionData = [];
        //是否配置
        let isConfigure = false;
        //判断是报价行组添加产品还是报价单
        if (this.groupIndex) {
          this.selectedList.forEach((selected) => {
            this.quotionData.push({
              product: selected.id,
              isconfig: selected.isconfig,
              quantity: selected.defaultquantity
                ? selected.defaultquantity
                : "1",
            });
          });
        } else {
          this.selectedList.forEach((selected) => {
            this.quotionData.push({
              product: selected.id,
              isconfig: selected.isconfig,
              quantity: selected.defaultquantity
                ? selected.defaultquantity
                : "1",
            });
          });
        }
        this.selectedList.some((selected) => {
          // "必须配置"
          if (selected.configurationtype === "必须配置") {
            isConfigure = true;
            return true;
          }
        });
        // 校验添加的产品是否符合规则
        this.$emit("checkData", this.quotionData, isConfigure);
      } else {
        //请至少选择一条
        this.$message.info(this.$i18n.t("lable.opppdt.selPdt.leastone"));
      }
    },
    goBack() {
      this.clearBtn();
      this.$parent.goBack();
    },
    // 获取选中的数据
    onchange(val) {
      this.selectedList = val;
      this.productId = "";
      this.selectedList.forEach((item) => {
        // "必须配置"
        if (item.configurationtype === "必须配置") {
          this.productId += "," + item.id;
        } else {
          item.isconfig = false;
        }
      });
      this.productId = this.productId.substr(1);
      localStorage.setItem("productId", JSON.stringify(this.productId));
    },
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    ::v-deep .text-center {
      th {
        .el-checkbox {
          display: none;
        }
      }
    }
    ::v-deep .statistics {
      padding: 0 10px;
      box-sizing: border-box;
      margin-top: 7px;
      p {
        margin-bottom: 10px !important;
      }
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
    }
    .price_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      .tit {
        line-height: 1.2;
        width: 30%;

        div {
          width: 100%;
          margin-top: 3px;
          p:first-child {
            font-size: 12px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #080707;
            font-weight: 600;
          }
        }
      }
      .search {
        float: right;
        width: 278px;
        margin-right: 10px;
        .btn_group {
          display: flex;
          justify-content: flex-end;
          .shaixuan {
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 3px;
            cursor: pointer;
            margin-right: 10px;
            svg {
              margin-left: -5px;
              margin-top: -1px;
              width: 16px;
              height: 16px;
            }
          }
          ::v-deep .el-button--primary:focus,
          .el-button--primary:hover {
            background: #005fb2;
            color: #fff;
            border-color: #005fb2;
          }
          .cancel_btn {
            ::v-deep .el-button--small,
            .el-button--small.is-round {
              padding: 8px 17px !important;
              color: #006dcc;
            }
            ::v-deep .el-button:hover {
              background: #fff;
              border: 1px solid #dedcda;
              color: #006dcc;
            }
          }
        }
        div {
          position: relative;
          svg {
            width: 16px;
            position: absolute;
            top: 7px;
            left: 10px;
          }
          ::v-deep .el-input__inner {
            height: 30px;
            padding-left: 32px;
            border: 1px solid #dedcda;
            font-size: 12px;
          }
        }
      }
      div {
        height: 30px;
        .viewImg {
          width: 37px;
          height: 37px;
          margin-right: 10px;
          margin-left: 10px;
          width: 37px;
          float: left;
        }
        p:first-child {
          width: 100%;
          margin-top: -2px;
          margin-bottom: 0;
        }
        p:nth-child(2) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }
      ::v-deep .el-input__inner {
        height: 30px;
        padding-left: 40px;
        border: 1px solid #c8c6c6;
      }
    }
  }
  .block {
    background: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    z-index: 9;
  }
}
</style>
