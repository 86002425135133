import { render, staticRenderFns } from "./quotation-table.vue?vue&type=template&id=49edc664&scoped=true&"
import script from "./quotation-table.vue?vue&type=script&lang=js&"
export * from "./quotation-table.vue?vue&type=script&lang=js&"
import style0 from "./quotation-table.vue?vue&type=style&index=0&id=49edc664&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49edc664",
  null
  
)

export default component.exports