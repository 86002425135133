<!--
 * @Description: 
 * @Author: 张亚欣
 * @Date: 2021-06-10 09:46:46
 * @LastEditTime: 2021-12-28 14:14:11
 * @LastEditors: Please set LastEditors
 * @Reference: 报价行表格组件
-->
<template>
  <!-- 报价行 -->
  <div
    class="quotation-table"
    :style="{
      borderTop:
        (lineGroup && lineGroup.length > 0) ||
        (tableData && tableData.length > 0)
          ? borderStyle
          : 0,
    }"
  >
    <ul v-if="tableData.length > 0">
      <!-- 多选框 -->
      <li class="checkbox-li">
        <el-checkbox v-model="checkAll"></el-checkbox>
      </li>
      <!-- 序号 -->
      <li class="number-li">#</li>
      <li
        ref="tableHeader"
        style="width: calc((100% - 210px) / 7)"
        v-for="(attr, idx) in tableAttr"
        :key="idx"
      >
        <el-tooltip class="item" :content="attr.label" placement="top">
          <span class="tootip-span">
            {{ attr.label }}
          </span>
        </el-tooltip>
      </li>
      <!-- 操作按钮 -->
      <li class="button-li">
        <span class="pull-right" @click="toggleExpand">
          <svg class="icon" aria-hidden="true">
            <use
              :href="expandAllLine ? '#icon-expandLine' : '#icon-expandLine1'"
            ></use>
          </svg>
        </span>
      </li>
    </ul>
    <draggable
      v-model="tableDataArr"
      group="site"
      animation="300"
      dragClass="dragClass"
      ghostClass="ghostClass"
      chosenClass="chosenClass"
      class="draggBox"
      ref="draggBox"
      :options="{ group: { name: 'site' }, sort: false }"
      :style="{
        maxHeight: draggBoxHeight,
        borderBottom:
          (lineGroup && lineGroup.length > 0) ||
          (tableDataArr && tableDataArr.length > 0)
            ? borderStyle
            : 0,
      }"
      @remove="remove($event)"
      :id="groupIndex"
    >
      <transition-group>
        <quotation-line
          v-for="(lineData, idx) in tableData"
          :key="idx"
          :quoteId="quoteId"
          :groupId="groupId"
          :id="lineData.index"
          :line-data="lineData"
          :tableAttr="tableAttr"
          :checkbox="checkbox"
          :checkAll="checkAll"
          :showIndex="showIndex"
          :buttonList="buttonList"
          :currency="currency"
          :editGroupIndex="editGroupIndex"
          :editLineIndex="editLineIndex"
          :editField="editField"
          :pricebookId="pricebookId"
          :expandAllLine="expandAllLine"
          :groupIndex="groupIndex"
          :moreQuoteLineFieldList="moreQuoteLineFieldList"
          @edit="edit"
          @modify="modify"
          @checkLine="checkLine"
          @deleteQuotation="deleteQuotation"
          @loading="loading"
          @copyQuotation="copyQuotation"
          @configQuotation="configQuotation"
        ></quotation-line>
      </transition-group>
    </draggable>

    <!-- 小计 subtotal -->
    <div v-if="customertotal !== undefined" class="subotalBox">
      <span class="subtotal">{{ $t("label.subtotal") }}</span>
      <span>{{ customertotal }}</span>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import QuotationLine from "./Line.vue";

export default {
  name: "editQuotation",
  components: {
    draggable,
    QuotationLine,
  },
  props: {
    // 行数据
    lineData: {
      type: Object,
      default: () => {},
    },
    //表头
    tableAttr: {
      type: Array,
      default: () => [],
    },
    // 展开行信息
    moreQuoteLineFieldList: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 币种
    currency: {
      type: String,
      default: "",
    },
    // 小计 - 只有报价行组有
    customertotal: {
      type: String,
      default: undefined,
    },
    // 报价单id
    quoteId: {
      type: String,
      default: "",
    },
    // 价格手册ID
    pricebookId: {
      type: String,
      default: "",
    },
    //报价行组id
    groupId: {
      type: String,
      default: "",
    },
    //所有报价行组信息
    lineGroup: {
      type: Array,
      default: () => [],
    },
    // 报价行所在分组下标
    groupIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      tableDataArr: this.tableData,
      tootip: true,
      checkAll: false, //选中全部数据
      draggBoxHeight: "500px",
      checkbox: true,
      showIndex: true,
      // t1: 0,
      // t2: 0,
      // timer: null,
      manualVisible: false,
      editGroupIndex: "", // 正在编辑数据所在组下标
      editLineIndex: "", // 正在编辑数据下标
      editLineId: "", // 正在编辑数据id
      editField: "", // 正在编辑数据属性
      buttonList: [
        // 提示信息
        {
          img: "#icon-infoQuotation",
          click: "quotationInfo",
        },
        // 配置产品
        {
          img: "#icon-configQuotation",
          click: "configQuotation",
          tip: this.$i18n.t("label.reconfigure.bundle"),
        },
        // 复制产品
        {
          img: "#icon-copyQuotation",
          click: "copyQuotation",
          tip: this.$i18n.t("label.copy.quote.lines"),
        },
        // 删除产品
        {
          img: "#icon-deleteQuotation",
          click: "deleteQuotation",
          tip: this.$i18n.t("label.deleteQuoteLine"),
        },
        // 折扣计划
        {
          img: "#icon-discountQuotation",
          click: "discount",
        },
        // 展开行
        {
          img: "#icon-expandLine1",
          click: "expandQuotation",
        },
        // 收起行
        {
          img: "#icon-expandLine",
          click: "hideQuotation",
        },
      ],
      expandAllLine: false,
      borderStyle: "1px solid #ccc",
    };
  },
  created() {
    this.draggBoxHeight = window.innerHeight - 364 + "px";
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll, true)
    this.setTableHeadWidth();
  },
  methods: {
    // 移除事件
    remove(e) {
      //报价行组下标
      let fromIndex = e.from.parentElement.id;
      let toIndex = e.to.parentElement.id;
      //报价行下标
      let cloneIndex = e.clone.id;

      this.$emit("draggleData", fromIndex, toIndex, cloneIndex);
    },
    // 展开/收起所有行
    toggleExpand() {
      this.expandAllLine = !this.expandAllLine;
      this.setTableHeadWidth();
    },
    // 添加loading
    loading() {
      this.$emit("loading");
    },
    // 根据表格数据是否有滚动条设置表头宽度
    setTableHeadWidth() {
      this.$nextTick(() => {
        let header = this.$refs.tableHeader;
        header &&
          header.forEach((head) => {
            if (
              this.$refs.draggBox.$el.scrollHeight >
              this.$refs.draggBox.$el.clientHeight
            ) {
              head.style.width = "calc((100% - 222px) / 7)";
            } else {
              head.style.width = "calc((100% - 210px) / 7)";
            }
          });
      });
    },
    // 报价行表格内联编辑
    edit(groupIndex, index, prop) {
      // this.editLineId = id
      this.editGroupIndex = groupIndex;
      this.editLineIndex = index;
      this.editField = prop;
    },
    // 修改
    modify(groupIndex, lineIndex, prop, inputNumber) {
      this.$emit("modify", groupIndex, lineIndex, prop, inputNumber);
    },
    // 选中/取消选中数据
    checkLine(checked, lineData, groupIndex) {
      this.$emit("checkLine", checked, lineData, groupIndex);
    },
    // 删除报价行
    deleteQuotation(groupIndex, lineIndex) {
      this.$emit("deleteQuotation", groupIndex, lineIndex);
    },
    //复制报价行
    copyQuotation(id) {
      this.$emit("copyQuotation", id);
    },
    configQuotation(list) {
      this.$emit("configQuotation", list, this.groupIndex);
    },
    // handleScroll(e) {
    //   // this.t1 = 0
    //   // this.t2 = 0
    //   //  let timer = null // 定时器
    //   if (this.timer !== null) {
    //     clearTimeout(this.timer)
    //   }
    //   this.timer = setTimeout(this.isScrollEnd, 0, e)
    //   // this.t1 = document.documentElement.scrollTop || document.body.scrollTop
    //   this.t1 = e.target.scrollTop
    //   this.tootip = false
    //   document.body.style.overflow = 'hidden'
    //   // 隐藏悬浮弹窗
    //   let tooptips = document.getElementsByClassName('el-tooltip__popper')
    //   tooptips.forEach((tooptip) => {
    //     // tooptip.style.display = 'none'
    //   })
    // },
    // isScrollEnd(e) {
    //   // this.t2 = document.documentElement.scrollTop || document.body.scrollTop
    //   this.t2 = e.target.scrollTop
    //   if (this.t2 == this.t1) {
    //     this.tootip = true
    //     
    //   }
    // },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.draggBox {
  overflow: auto;
  min-height: 38px;
  span {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    min-height: 100%;
  }
}

.quotation-table {
  width: 100%;
  clear: both;
  background: #fff;
  border-radius: 3px;

  ul {
    margin-bottom: 0;
    display: flex;
    background: #fafaf9;

    li {
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #3e3e3c;
      border-left: 1px solid #dedcda;
    }

    .tootip-span {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .checkbox-li {
      width: 30px;
      padding: 0;
      text-align: center;
      border: none;
    }

    .number-li {
      width: 40px;
      padding: 0;
      text-align: center;
    }

    .button-li {
      width: 135px;

      .icon {
        width: 12px;
        margin-top: 13px;
        cursor: pointer;
      }
    }
  }
}

.subotalBox {
  width: 100%;
  height: 30px;
  line-height: 20px;
  margin-top: 10px;
  padding-left: calc((100% - 210px) / 7 * 5 + 70px);

  .subtotal {
    display: inline-block;
    width: calc((100% - 130px) / 2);
    padding-left: 5px;
  }
}

/*修改滚动条样式*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /**/
}
::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
</style>
